import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactUs = () => {
  return (
    <Layout formTitle="Have a question? Let us know how we can help.">
      <Seo 
        title="Contact Us"
        description="A family owned and operated log home design and lumber mill located in Vermont. We manufacture quality custom log homes and dimensional milled lumber." 
      />
      <div className="container mx-auto pt-32 md:pt-48">
        <h1>Contact Us</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-16">
          <div className="p-6 border">
            <h3>Phone</h3>
            <p className="mb-2"><a href="tel:802-472-5096">802-472-5096</a></p>
            <h3>Email</h3>
            <p className="mb-2"><a href="mailto:info@vtlogs.com">info@vtlogs.com</a></p>
          </div>
          <div className="p-6 border">
            <h3>Address</h3>
            <p>1670 Craftsbury Rd.<br/>Hardwick, Vermont 05843</p>
          </div>
          <div className="p-6 border">
            <h3>Hours</h3>
            <ul>
              <li><strong>Monday - Friday</strong><br />7:00AM - 3:30PM</li>
              <li><strong>Saturday &amp; Sunday</strong><br />Closed</li>
            </ul>
          </div>
        </div>
        <hr className="my-24 md:my-36" />
      </div>
    </Layout>
  );
}
 
export default ContactUs;